import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Tokyo = () => (
  <Layout>
    <GatsbySeo
      title="Universidad de Tokyo"
      titleTemplate="%s | Eduardo Ceballos"
      description=""
      canonical="https://eduardoceballos.com/tokyo"
      openGraph={{
        url: `https://eduardoceballos.com/tokyo`,
        title: `Universidad de Tokyo`,
        description: ``,
        images: [
          {
            url: `https://eduardoceballos.com/seo/tokyo.png`,
            alt: ``,
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>POR AMOR A LA VIDA</h1>
    <h6>
      <a href="https://spanish.ecc.u-tokyo.ac.jp/aries/amorvida/index.html">
        Fuente original.
      </a>
    </h6>
    <div className="tokyo-menu-flex">
      <Link to="/tokyo/1">1. Entrevista</Link>
      <Link to="/tokyo/2">2. Recitación</Link>
      <Link to="/tokyo/3">3. Equipo de producción</Link>
      <Link to="/tokyo/4">4. Agradecimientos</Link>
    </div>
    <p>
      En septiembre de 2008, primavera en Argentina, visitamos la ciudad de
      Salta, donde recibimos la amable colaboración de la gente de la ciudad.
      Hicimos una entrevista al poeta Eduardo Ceballos, quien nos explicó sus
      inquietudes sobre la historia y el presente de nuestro planeta. Nos hizo
      el favor de leer toda su obra: <i>Por amor a la vida</i>.
    </p>
  </Layout>
)

export default Tokyo
